import { Details, Summary } from '@troon/ui';
import { For, Suspense } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { NoHydration } from 'solid-js/web';
import { twJoin } from '@troon/tailwind-preset/merge';
import { createContentfulRequest } from '../routes/api/content/[model]/_client';
import { RichText } from './rich-text';

type Props = {
	id: string;
	fullWidth?: boolean;
	layout?: 'tight';
};

export function FrequentlyAskedQuestions(props: Props) {
	const faqs = createAsync(async () => request(props.id), { deferStream: true });

	return (
		<NoHydration>
			<Suspense>
				<ul class="divide-y divide-neutral-500">
					<For each={faqs()?.fields.faq}>
						{(faq) => (
							<li>
								<Details>
									<Summary
										class={twJoin(
											'group-open:mb-0',
											!props.fullWidth && 'px-4',
											props.layout === 'tight' ? 'py-4 group-open:pb-2' : 'py-6 group-open:pb-4',
										)}
									>
										{faq?.fields.question}
									</Summary>
									<div class={twJoin('pb-4', !props.fullWidth && 'px-4')}>
										<RichText document={faq!.fields.answer} />
									</div>
								</Details>
							</li>
						)}
					</For>
				</ul>
			</Suspense>
		</NoHydration>
	);
}

const request = createContentfulRequest('faqs');
